<template>
  <div class="text-center mb-3">
    <h1 class="mb-3">Epic Road Trip Planner</h1>
    <p class="lead text-mute mb-4">AI-powered tools for planning your next epic road trip.</p>
  </div>
  <div class="mb-3">
    <ERTPNewRoadtrip
      @update:model-value="onNewRoadtrip"
      :defaultOriginGpid="defaultOriginGpid"
      :defaultDestinationGpid="defaultDestinationGpid"
    />
  </div>
  <div class="mb-3">
    <ERTPRecentRoadtrips
      class="border border-rounded rounded-3 p-3"
      :max-items=5 
      v-show="userHasRoadtrips"
    />
  </div>
  <div>      
    <ERTPSignUpEncouragement v-if="showSignUpEncouragement" class="mb-3"/>
    <ERTPSubscriptionEncouragement v-else-if="mustSubscribeForMore" class="mb-3"/>
  </div>

  <div v-if="alertMessage" class="alert alert-warning text-center mt-3" role="alert">
    {{ alertMessage }}
  </div>
</template>

<script setup lang="ts">
import ERTPRecentRoadtrips from "../../components/ERTPRecentRoadtrips.vue";
import ERTPNewRoadtrip from "../../components/ERTPNewRoadtrip.vue";
import ERTPSignUpEncouragement from "../../components/ERTPSignUpEncouragement.vue";
import ERTPSubscriptionEncouragement from "../../components/ERTPSubscriptionEncouragement.vue";

import { computed } from "vue";
import { GoogleService } from "../../services/googleService";
import { Roadtrip } from "../../models/roadtrip";
import { injectRequired } from "../../utilities/injectRequired";
import { 
  DEFAULT_MAX_SIGNEDIN_ROADTRIPS,
} from '../../models/profile';
import { useResultMessage } from "../../composables/resultMessage";
import { useRoadtrips } from "../../composables/roadtrips";
import { useProfile } from "../../composables/profile";
import { navigate } from "vike/client/router";
import { useUserState } from "../../composables/userState";
import { useServices } from "../../composables/services";
import { usePageContext } from 'vike-vue/usePageContext';

const pageContext = usePageContext();

const { userState } = useUserState();

const googleService = injectRequired<GoogleService>('googleService');

const {
  profilesService,
  roadtripsService,
} = useServices();

const {
  roadtrips,
} = useRoadtrips();

const {
  profile,
} = useProfile();

const defaultDestinationGpid = getGooglePlaceId('ertp_destination_gpid')
const defaultOriginGpid = getGooglePlaceId('ertp_orig_gpid');

function getGooglePlaceId(queryParam: string): string | undefined {
  const placeIdParam = pageContext.urlParsed.search[queryParam];
  if (placeIdParam) {
    return placeIdParam.toString();
  }
}

const isReady = computed(() => {
  if (userState.value.loading) return false;
  if (googleService.isLoading.value) return false;
  if (roadtripsService.isLoading.value) return false;
  if (profilesService.isLoading.value) return false;
  return true;
});
 
const userHasRoadtrips = computed(() => (
  userState.value.isSignedIn 
  && roadtrips.value 
  && roadtrips.value.length
));

const showSignUpEncouragement = computed(() => {
  if(!isReady.value) return false; // Don't need to encourage them to sign in if they're in the midst of auth action
  if(!userState.value.isSignedIn) return false; // Only show this if they're signed in ANONYMOUSLY
  if(!userState.value.isAnonymous) return false; // Already signed in non-anonymously, signing in won't do any good.
  if(!roadtrips.value?.length) return false; // They haven't created the first road trip yet, they don't need to sign in
  return true;
});

const mustSubscribeForMore = computed(() => {
  if(userState.value.loading) return false; // Don't need to encourage them to sign in if they're in the midst of auth action
  if(userState.value.isAnonymous) return false; // They're anonymous, they CAN'T subscribe; so don't tell them to.
  if(!userState.value.isSignedIn) return false; // They're not signed in, they CAN'T subscribe; so don't tell them to
  if(profilesService.isLoading.value) return false; // Loading; can't decide yet if they need to subscribe to create more roadtrips 
  if(profilesService.hasSubscription.value) return false; // They already have a subscription, subscribing again won't help them.
  if(!profile.value) return false; // No profile, so they have to sign up before they can subscribe (for more road trips)
  if(roadtripsService.isLoading.value) return false; // We don't know what to compare against (yet)
  // Similarly to the above sanity check, this SHOULDN'T happen, by design, but if it does, deal with it ratioanlly
  if(!roadtrips.value) { console.warn('Roadtrips are loaded, but empty - no way to determine how many we have.'); return false; }

  const maxAllowedRoadtrips = profile.value.maxAllowedRoadtrips || DEFAULT_MAX_SIGNEDIN_ROADTRIPS;
  return (roadtrips.value.length >= maxAllowedRoadtrips);
});

const { resultMessage } = useResultMessage();

const alertMessage = computed(() => 
  resultMessage.value 
  || googleService?.lastErrorMessage.value
  || profilesService?.lastErrorMessage.value 
  || roadtripsService?.lastErrorMessage.value);


function onNewRoadtrip(roadtrip?: Roadtrip) {
  if (roadtrip?.link) navigate(roadtrip.link);  
}

</script>
